import { useState } from 'react'
import { CardLoading, MatesButton } from '../../atoms/atoms'
import RecommendedVideoSheet from '../../molecules/modals/RecommendedVideoSheet'
import { useMatesSelector } from '../../../app/hooks'
import { resourceSelector } from '../../../features/resources/Resource/resourceSlice'
import { useQuery } from 'react-query'
import ResourceService from '../../../services/react-query/ResourceService'
import { INDIVIDUAL_RECOMMENDED_RESOURCE } from '../../../assets/data/constants'
import SmallResourceCard from '../../atoms/cards/SmallResourceCard'

type Props = {
	resourceId: string
}

const RecommendedVideos = ({ resourceId }: Props) => {
	const [isSheetOpen, setIsSheetOpen] = useState(false)

	const resourceState = useMatesSelector(resourceSelector)

	const { data, isLoading, isError } = useQuery({
		queryFn: () => ResourceService.getResource(resourceState.recommendedResourceId ?? ''),
		queryKey: [INDIVIDUAL_RECOMMENDED_RESOURCE, resourceState.recommendedResourceId],
		enabled: !!resourceState.recommendedResourceId,
	})

	const recommendedResource = data?.data

	const renderRecommendedResource = () => {
		if (!recommendedResource || !resourceState.recommendedResourceId)
			return <p className='text-MatesWhite '>Select the recommended video for users to watch afterwards.</p>
		if (isLoading) return <CardLoading />
		return <SmallResourceCard resource={recommendedResource} />
	}

	return (
		<div className='mt-8 bg-MatesLightBlack p-4 font-condensed rounded-[5px]'>
			<RecommendedVideoSheet isOpen={isSheetOpen} closeSheet={() => setIsSheetOpen(false)} resourceId={resourceId} />
			<p className='text-MatesWhite font-medium text-xl tracking-wide mb-2'>RECOMMENDED</p>
			<div className='flex items-center justify-between w-full mb-2'>{renderRecommendedResource()}</div>
			<MatesButton text='ADD VIDEO' onSubmit={() => setIsSheetOpen(true)} className={`base-button active-button tracking-wider text-xl`} />
		</div>
	)
}

export default RecommendedVideos
