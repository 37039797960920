import { AnimatePresence, domAnimation, LazyMotion, motion } from 'framer-motion'
import TitleCard from '../../atoms/cards/TitleCard'
import RightArrowIcon from '../../../assets/icons/svg/rightArrowIcon'
import { Category } from '../../../types/stateTypes'
import { cardAnimations } from '../../../assets/data/InitialState'

type Props = {
	category: Category
	onCategoryClick: () => void
	isActive: boolean
}

const CategoryCard = ({ category, onCategoryClick, isActive }: Props) => {
	return (
		<AnimatePresence>
			<LazyMotion features={domAnimation} key='category-card'>
				<div className='w-full'>
					<div>
						<motion.div className='flex w-full' {...cardAnimations}>
							<div className='relative w-full'>
								{category.isAvailableToEveryone && (
									<div
										className='absolute left-0 top-2 bottom-2 w-[6px] 
                                                        rounded-l-[3px] bg-MatesGreen'
									></div>
								)}
								<TitleCard isSelected={isActive} isAnimationOn={false} onClick={onCategoryClick}>
									<div className='flex items-center justify-between w-full tracking-wider'>
										<p className='text-[20px] font-compressed font-bold pt-[2px]'>{category.title}</p>
										<RightArrowIcon className='ml-2' />
									</div>
								</TitleCard>
							</div>
						</motion.div>
					</div>
				</div>
			</LazyMotion>
		</AnimatePresence>
	)
}

export default CategoryCard
