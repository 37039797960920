import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { MatesApi } from '../app/api'
import { storeUserGroup, storeUserGroups } from '../features/resources/userGroupsSlice'
import { Category, UserGroup } from '../types/stateTypes'
import { storeCategories } from '../features/resources/categoriesSlice'
import { GetCategoriesResponse, UpdateUserGroupArgs } from '../types/serviceTypes'

export const userGroupApi = MatesApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserGroups: builder.query<UserGroup[], void>({
			async queryFn(shouldStore, api, extraOptions, baseQuery) {
				const { data, error } = await baseQuery({
					url: '/user-groups',
					params: { page: 1, take: 10 },
				})
				if (error) return { error: error as FetchBaseQueryError }
				api.dispatch(storeUserGroups(data as UserGroup[]))
				return { data: data as UserGroup[] }
			},
			providesTags: ['UserGroup'],
		}),
		getCategories: builder.query<Category[], { id: string; shouldStore: boolean }>({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { data, error } = await baseQuery({
					url: `/user-groups/${arg.id}/categories`,
					params: { page: 1, take: 100 },
				})
				if (error) return { error: error as FetchBaseQueryError }
				const { categories } = data as GetCategoriesResponse

				if (arg.shouldStore) api.dispatch(storeCategories(categories))
				return { data: categories }
			},
			providesTags: ['UserGroup', 'Category'],
		}),
		getDuplicateResourcesCategories: builder.query<Category[], string>({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { data, error } = await baseQuery({
					url: `/user-groups/${arg}/categories`,
					params: { page: 1, take: 100 },
				})
				if (error) return { error: error as FetchBaseQueryError }
				const { categories } = data as GetCategoriesResponse
				return { data: categories }
			},
		}),
		updateUserGroupDetails: builder.mutation<UserGroup, UpdateUserGroupArgs>({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { data, error } = await baseQuery({
					url: `/admin/user-groups/${arg.id}`,
					method: 'PATCH',
					body: {
						thumbnail: arg.thumbnail,
						videoLink: arg.videoLink,
						discoverResourcesDescription: arg.discoverResourcesDescription,
						discoverForYourselfDescription: arg.discoverForYourselfDescription,
						slug: arg.slug,
					},
				})

				if (error) return { error: error as FetchBaseQueryError }
				const userGroupData = data as UserGroup
				api.dispatch(storeUserGroup(userGroupData))
				return { data: userGroupData }
			},
			invalidatesTags: ['UserGroup'],
		}),
	}),
})

export const { useGetUserGroupsQuery, useGetCategoriesQuery, useUpdateUserGroupDetailsMutation, useGetDuplicateResourcesCategoriesQuery } =
	userGroupApi
