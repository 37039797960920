import { UpdateResourceArgs } from '../../../types/serviceTypes'
import { RFC } from '../../../types/propTypes'
import { useMatesDispatch } from '../../../app/hooks'
import {
	updateResourceIsActive,
	updateResourcePin,
	updateSlug,
	updateTitle,
	updateVideoDescription,
	updateVideoLink,
	updateVideoPin,
	updateVideoThumbnail,
} from '../../../features/resources/Resource/resourceSlice'
import { PinResourceField } from '../../molecules/molecules'
import { MatesButton, MatesLinkInput, MatesTextInput, MatesUploadInput } from '../../atoms/atoms'
import { BinIcon } from '../../../assets/icons/icons'
import IsActiveField from '../../molecules/fields/IsActiveField'

type VideoDetailProps = {
	resource: UpdateResourceArgs
	onSubmit: () => void
	onDelete: () => void
}

const VideoDetail: RFC<VideoDetailProps> = ({ resource, onSubmit, onDelete }) => {
	const { isPinned, title, video, isActive, slug } = resource
	const isValid = title && video?.thumbnail && video.url
	const dispatch = useMatesDispatch()

	return (
		<>
			<PinResourceField
				isPinned={video?.isHotTopic ?? false}
				title='Pin as hot topic'
				onChange={() => dispatch(updateVideoPin(!video?.isHotTopic))}
			/>
			<PinResourceField isPinned={isPinned} onChange={() => dispatch(updateResourcePin(!isPinned))} />
			<IsActiveField isActive={isActive} onChange={() => dispatch(updateResourceIsActive(!isActive))} />
			<MatesTextInput
				name='videoTitle'
				label='TITLE'
				theme='dark'
				value={title}
				className={'pt-3'}
				onChange={(title) => dispatch(updateTitle(title))}
			/>
			<MatesTextInput
				name='videoSlug'
				label='SLUG'
				theme='dark'
				value={slug}
				className={'pt-3'}
				onChange={(title) => dispatch(updateSlug(title))}
			/>
			<MatesTextInput
				name='videoDescription'
				label='DESCRIPTION'
				theme='dark'
				value={video?.description ?? ''}
				className={'pt-3'}
				onChange={(description) => dispatch(updateVideoDescription(description))}
			/>
			<MatesUploadInput
				title='VIDEO THUMBNAIL'
				theme='dark'
				type='image'
				source={video?.thumbnail}
				onUpload={(image) => dispatch(updateVideoThumbnail(image ?? ''))}
				folder={'video_thumbnails'}
				rootFolder={'images'}
			/>
			<MatesLinkInput
				title='RESOURCE'
				theme='dark'
				source={video?.url}
				placeholder='Paste link here'
				onChange={(link) => dispatch(updateVideoLink(link))}
			/>
			<div className='flex items-center'>
				<MatesButton
					text='SAVE'
					className={`base-button mt-7
          ${isValid ? 'active-button' : 'disabled-dark-button'} tracking-wider text-xl`}
					disabled={!isValid}
					onSubmit={onSubmit}
				/>
				<div
					className='base-button bg-MatesDarkRed 
          mt-7 ml-4 px-3 py-4 cursor-pointer group'
					onClick={onDelete}
				>
					<BinIcon
						className='group-hover:scale-125
            transition-all duration-300'
					/>
				</div>
			</div>
		</>
	)
}

export default VideoDetail
