import { Dialog } from '@headlessui/react'
import { motion } from 'framer-motion'
import { MatesButton, MatesLoading } from '../../atoms/atoms'
import { useGetUserGroupsQuery } from '../../../services/UserGroupService'
import { useState } from 'react'
import { Category, Resource, UserGroup } from '../../../types/stateTypes'
import UserGroupCategoriesList from '../../resources/UserGroupCategoriesList'
import InfiniteRecommendedVideosList from '../lists/InfiniteResourceListRecommendedVideos'
import SmallResourceCard from '../../atoms/cards/SmallResourceCard'
import { useMutation } from 'react-query'
import ResourceService from '../../../services/react-query/ResourceService'
import { useDispatch } from 'react-redux'
import { resourceSlice } from '../../../features/resources/Resource/resourceSlice'
import { resourceApi } from '../../../services/ResourceService'

type Props = {
	isOpen: boolean
	closeSheet: () => void
	resourceId: string
}

const RecommendedVideoSheet = ({ isOpen, closeSheet, resourceId }: Props) => {
	const { data: userGroups, isLoading: isLoadingUserGroup } = useGetUserGroupsQuery()

	const dispatch = useDispatch()

	const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>()
	const [selectedCategory, setSelectedCategory] = useState<Category>()
	const [selectedRecommendedVideo, setSelectedRecommendedVideo] = useState<Resource>()

	const { mutate: updateRecommendedVideo, isLoading } = useMutation({
		mutationFn: ResourceService.patchResource,
		onSuccess: () => {
			dispatch(resourceApi.util.invalidateTags(['Resource']))
			closeSheet()
		},
	})

	const saveRecommendedVideo = () => {
		updateRecommendedVideo({
			id: resourceId,
			data: {
				recommendedResourceId: selectedRecommendedVideo?.id,
			},
		})
	}

	const renderUserGroups = () => {
		if (!userGroups || !userGroups.length || isLoadingUserGroup === undefined) return null

		return userGroups.map((userGroup, index) => (
			<div key={index}>
				{isLoadingUserGroup ? (
					<div key={index} className='title-button'>
						<MatesLoading shape='circle' />
					</div>
				) : (
					<div
						key={index}
						className={`title-button whitespace-nowrap text-MatesWhite p-2 text-center
				  ${userGroup === selectedUserGroup ? 'bg-MatesRed hover:bg-MatesRed' : 'bg-MatesDarkGrey hover:bg-MatesBorder'}`}
						onClick={() => setSelectedUserGroup(userGroup)}
					>
						{userGroup.title}
					</div>
				)}
			</div>
		))
	}

	return (
		<Dialog open={isOpen} onClose={closeSheet} className='relative z-50'>
			<div className='fixed inset-0 bg-black bg-opacity-50' aria-hidden='true' />

			<motion.div
				initial={{ x: '100%' }}
				animate={{ x: isOpen ? '0%' : '100%' }}
				exit={{ x: '100%' }}
				transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				className='fixed inset-y-0 right-0 w-[50%] bg-MatesBlack shadow-xl p-6 overflow-y-auto z-10'
				onClick={(e) => e.stopPropagation()}
			>
				<Dialog.Panel>
					<section className='flex justify-between w-full'>
						<h2 className='text-white font-bold text-[40px] leading-[72px] '>RECOMMENDED</h2>
						<section className='flex gap-2 items-center'>
							<MatesButton text='CANCEL' onSubmit={closeSheet} className='base-button active-button tracking-wider text-xl bg-white text-black' />
							<MatesButton
								text='SAVE AND CLOSE'
								onSubmit={saveRecommendedVideo}
								className='base-button active-button tracking-wider text-xl'
								isLoading={isLoading}
							/>
						</section>
					</section>

					<section className='border-b-2 border-MatesDisabled w-full p-2' />

					<section className='flex gap-2 overflow-x-auto p-4'>{renderUserGroups()}</section>

					<section className='grid grid-cols-2 gap-6 text-white'>
						<section>
							<section>
								<h2 className='text-MatesRed font-semibold text-2xl mb-2'>CATEGORIES</h2>

								{selectedUserGroup ? (
									<UserGroupCategoriesList userGroup={selectedUserGroup} selectedCategory={selectedCategory} onCategoryClick={setSelectedCategory} />
								) : (
									<p className='text-white'>Please select a user group</p>
								)}
							</section>

							<section className='border-b-2 border-MatesDisabled w-full p-2 mb-8' />

							<section>
								<h2 className='text-MatesRed font-semibold text-2xl mb-2'>YOUR SELECTED</h2>
								{selectedRecommendedVideo ? (
									<SmallResourceCard resource={selectedRecommendedVideo} />
								) : (
									<p>The resource you select will appear here</p>
								)}
								<p></p>
							</section>
						</section>
						<section>
							<h2 className='text-MatesRed font-semibold text-2xl mb-2'>VIDEOS</h2>
							{selectedCategory ? (
								<InfiniteRecommendedVideosList
									categoryId={selectedCategory.id}
									selectedVideo={selectedRecommendedVideo}
									onVideoClick={(video) => setSelectedRecommendedVideo(video)}
								/>
							) : (
								<p className='text-white'>Please select a category</p>
							)}
						</section>
					</section>
				</Dialog.Panel>
			</motion.div>
		</Dialog>
	)
}

export default RecommendedVideoSheet
