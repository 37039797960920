import InfiniteScroll from 'react-infinite-scroller'
import { CardError, CardLoading, CardLogo } from '../../atoms/atoms'
import { RFC } from '../../../types/propTypes'
import { Resource } from '../../../types/stateTypes'
import { AnimatePresence, domAnimation, LazyMotion, motion } from 'framer-motion'
import { cardAnimations } from '../../../assets/data/InitialState'
import RecommendedVideoCard from '../../atoms/cards/RecommendedVideoCard'
import { useInfiniteQuery } from 'react-query'
import ResourceService from '../../../services/react-query/ResourceService'
import { RECOMMENDED_VIDEOS_KEY } from '../../../assets/data/constants'
import { getItemsFromPagination, getNextPageParam } from '../../../utils/query/query'

type InfiniteRecommendedVideosListProps = {
	categoryId: string
	selectedVideo: Resource | undefined
	onVideoClick: (video: Resource) => void
}

const perPage = 5
const InfiniteRecommendedVideosList: RFC<InfiniteRecommendedVideosListProps> = ({ categoryId, selectedVideo, onVideoClick }) => {
	const { data, isLoading, fetchNextPage, hasNextPage, isError } = useInfiniteQuery({
		queryFn: ({ pageParam = 1 }) =>
			ResourceService.getResources({
				page: pageParam,
				perPage,
				categoryId,
				type: 'VIDEO',
			}),
		queryKey: [RECOMMENDED_VIDEOS_KEY, categoryId],
		getNextPageParam: getNextPageParam(perPage),
	})

	const videos = getItemsFromPagination(data)

	const renderVideos = () => {
		if (isLoading) return <CardLogo />
		if (isError) return <CardError />
		if (!videos.length) return <div className='empty-list font-condensed'>No Videos</div>

		return (
			<AnimatePresence>
				<LazyMotion features={domAnimation} key='resource-card'>
					{videos.map((video, index) => (
						<motion.div key={index} className='flex w-full pr-2' {...cardAnimations}>
							<RecommendedVideoCard resource={video} isSelected={selectedVideo === video} onClick={() => onVideoClick(video)} />
						</motion.div>
					))}
				</LazyMotion>
			</AnimatePresence>
		)
	}

	return (
		<div className='w-full h-[calc(100vh-20em)] overflow-y-auto overflow-x-hidden'>
			<InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} useWindow={false} loader={<CardLoading key={0} />} threshold={50}>
				{renderVideos()}
			</InfiniteScroll>
		</div>
	)
}

export default InfiniteRecommendedVideosList
