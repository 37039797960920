import moment from 'moment'
import { ResourceListType, Tuple } from '../types/propTypes'
import { CreateResourceAPIArgs, CreateResourceArgs, UpdateCategoryArgs, UpdateResourceArgs } from '../types/serviceTypes'
import { Category, Challenge, Winner } from '../types/stateTypes'
import { TopPick } from '../services/react-query/ChallengeService'

export const formatUpdateCategory = (initial: boolean, newCategory: Category): UpdateCategoryArgs => {
	if (initial && initial === newCategory.isAvailableToEveryone)
		return {
			id: newCategory.id,
			title: newCategory.title,
			slug: newCategory.slug,
			isHidden: newCategory.isHidden,
			userGroupId: newCategory.userGroupId,
			sequence: newCategory.sequence,
			isTrainingModule: newCategory.isTrainingModule,
			thumbnail: newCategory.thumbnail,
			description: newCategory.description,
		}
	else
		return {
			id: newCategory.id,
			slug: newCategory.slug,
			isHidden: newCategory.isHidden,
			isAvailableToEveryone: newCategory.isAvailableToEveryone,
			userGroupId: newCategory.userGroupId,
			sequence: newCategory.sequence,
			isTrainingModule: newCategory.isTrainingModule,
			thumbnail: newCategory.thumbnail,
			description: newCategory.description,
		}
}

type newResourceType = {
	link: {
		url: string
		description: string
	} | null
	pdf: {
		description: string
		file: string
	} | null
	podcast: {
		description: string
		file: string
	} | null
	video: {
		description?: string
		isHotTopic: boolean
		url: string
		thumbnail?: string
	} | null
}

const getTypeArg = (type: ResourceListType) => {
	switch (type) {
		case 'LINKS':
			return 'LINK'
		case 'PDFS':
			return 'PDF'
		case 'PODCASTS':
			return 'PODCAST'
		case 'VIDEOS':
			return 'VIDEO'
		default:
			return ''
	}
}

export const formatCreateResource = (resource: CreateResourceArgs, resourceType: ResourceListType | undefined): CreateResourceAPIArgs => {
	const defaultObj = {
		link: null,
		pdf: null,
		podcast: null,
		video: null,
	}

	const resources = (): newResourceType => {
		switch (resourceType) {
			case 'PODCASTS': {
				if (!resource.podcast) return defaultObj
				return {
					link: null,
					pdf: null,
					podcast: {
						description: resource.podcast.description,
						file: resource.podcast.file,
					},
					video: null,
				}
			}
			case 'VIDEOS': {
				if (!resource.video) return defaultObj
				return {
					link: null,
					pdf: null,
					podcast: null,
					video: {
						description: resource.video.description,
						isHotTopic: false,
						url: resource.video.url,
						thumbnail: resource.video.thumbnail,
					},
				}
			}
			case 'PDFS': {
				if (!resource.pdf) return defaultObj
				return {
					link: null,
					pdf: {
						description: resource.pdf.description,
						file: resource.pdf.file,
					},
					podcast: null,
					video: null,
				}
			}
			case 'LINKS': {
				if (!resource.link) return defaultObj
				return {
					link: {
						url: resource.link.url,
						description: resource.link.description,
					},
					pdf: null,
					podcast: null,
					video: null,
				}
			}
			default:
				return defaultObj
		}
	}

	return {
		title: resource.title,
		slug: resource.slug,
		categoryId: resource.categoryId,
		type: getTypeArg(resource.openedTab),
		isActive: true,
		isPinned: false,
		link: resources().link,
		pdf: resources().pdf,
		podcast: resources().podcast,
		video: resources().video,
	}
}

export const formatResource = (resource: UpdateResourceArgs, resourceType: ResourceListType | undefined): UpdateResourceArgs => {
	const defaultObj = {
		link: null,
		pdf: null,
		podcast: null,
		video: null,
	}

	const resources = (): newResourceType => {
		switch (resourceType) {
			case 'PODCASTS': {
				if (!resource.podcast) return defaultObj
				return {
					link: null,
					pdf: null,
					podcast: {
						description: resource.podcast.description,
						file: resource.podcast.file,
					},
					video: null,
				}
			}
			case 'VIDEOS': {
				if (!resource.video) return defaultObj
				return {
					link: null,
					pdf: null,
					podcast: null,
					video: {
						description: resource.video.description,
						isHotTopic: resource.video.isHotTopic,
						url: resource.video.url,
						thumbnail: resource.video.thumbnail,
					},
				}
			}
			case 'PDFS': {
				if (!resource.pdf) return defaultObj
				return {
					link: null,
					pdf: {
						description: resource.pdf.description,
						file: resource.pdf.file,
					},
					podcast: null,
					video: null,
				}
			}
			case 'LINKS': {
				if (!resource.link) return defaultObj
				return {
					link: {
						url: resource.link.url,
						description: resource.link.description,
					},
					pdf: null,
					podcast: null,
					video: null,
				}
			}
			default:
				return defaultObj
		}
	}

	return {
		...resource,
		link: resources().link,
		pdf: resources().pdf,
		podcast: resources().podcast,
		video: resources().video,
	}
}

export const formatDuration = (duration: number) => {
	const hours = Math.floor(duration / 3600)
	const minutes = Math.floor((duration % 3600) / 60)
	const seconds = Math.floor(duration % 60)

	if (hours >= 1) {
		return `${hours} hr ${minutes} min`
	} else if (minutes >= 1) {
		return `${minutes} min ${seconds} sec`
	} else {
		return `${seconds} sec`
	}
}

export const formatFileName = (fileName: string) => {
	const fileNameWithDate = fileName.split('/')[2]
	if (!fileNameWithDate) return 'Failed to download file name'
	return fileNameWithDate.slice(24)
}

export const cardDateFormatter = (date: string) => {
	// 01 Nov 2023
	if (date === '') return ''
	const formattedDate = moment(date).format('DD MMM YYYY')
	return formattedDate
}

export const mobileNewformat = (number: string) => {
	const regex = /(\d{4})\D?(\d{3})\D?(\d{3})/
	const mobileUpdated = number.replace(regex, '$1 $2 $3')
	return mobileUpdated
}

export const formatFullNameToUpperCase = (firstName: string, lastName: string) => {
	const firstNameUpper = firstName.toUpperCase()
	const lastNameUpper = lastName.toUpperCase()
	return `${firstNameUpper} ${lastNameUpper}`
}

export const formatChallengeRanking = (index: number): string => {
	const ranking = index + 1
	if (index === 0) return '1ST'
	else if (index === 1) return '2ND'
	else if (index === 2) return '3RD'
	else return `${ranking}TH`
}

export const formatInitialWinners = (challenge: Challenge): Tuple<Winner, 5> => {
	const { winners } = challenge
	const filledWinners = new Array(5).fill({ id: '', link: '', ranking: '' })
	if (!winners.length) return filledWinners as Tuple<Winner, 5>
	else {
		for (let i = 0; i < winners.length; i++) {
			filledWinners[i] = winners[i]
		}
		return filledWinners as Tuple<Winner, 5>
	}
}

export const formatWinnersWithoutId = (winners: Tuple<Winner, 5>) => {
	const formattedWinners = winners
		.filter((winner) => winner.link)
		.map((winner) => ({
			link: winner.link,
			ranking: winner.ranking,
		}))
	return formattedWinners
}

/** Ranks topPicks by like count and in a shape suitable for the winners endpoint */
export const formatTopPicksAsWinners = (topPicks: TopPick[]) => {
	topPicks.sort((a, b) => b._count.likes - a._count.likes)
	const formattedWinners = topPicks
		.filter((topPick) => topPick.link)
		.map((topPick, index) => ({
			link: topPick.link,
			ranking: index,
		}))
	return formattedWinners
}

export const formatSlug = (slug: string): string => {
	return slug.toLowerCase().replaceAll(/([^A-Za-z])/g, '-')
}
