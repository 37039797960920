import { Resource } from '../../../types/stateTypes'
import { MatesAsyncImage } from '../atoms'

type Props = {
	resource: Resource
}

const SmallResourceCard = ({ resource }: Props) => {
	const { title, video, category } = resource

	return (
		<div className='relative w-full h-full grid grid-cols-2 gap-6 items-center'>
			<div className='relative w-full aspect-video'>
				<MatesAsyncImage fileName={video?.thumbnail} />
			</div>
			<section>
				<p
					className='w-full text-white/60
              uppercase text-2xl line-clamp-2 font-compressed tracking-wide'
				>
					{category.title}
				</p>
				<p
					className='w-full
              uppercase text-2xl line-clamp-2 font-compressed tracking-wide text-white'
				>
					{title}
				</p>
			</section>
		</div>
	)
}

export default SmallResourceCard
