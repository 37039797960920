import { useGetCategoriesQuery } from '../../services/UserGroupService'
import { Category, UserGroup } from '../../types/stateTypes'
import { CardLoading } from '../atoms/atoms'
import CategoryCard from '../organisms/cards/CategoryCard'

type Props = {
	userGroup: UserGroup
	selectedCategory: Category | undefined
	onCategoryClick: (category: Category) => void
}

const UserGroupCategoriesList = ({ userGroup, selectedCategory, onCategoryClick }: Props) => {
	const { data: categories, isLoading } = useGetCategoriesQuery(
		{ id: userGroup.id, shouldStore: false },
		{ refetchOnMountOrArgChange: true }
	)

	const renderCategories = () => {
		if (isLoading) return <CardLoading />
		if (!categories || categories.length === 0) return <p>No categories found, please try another user group!</p>

		return categories.map((category) => (
			<CategoryCard
				category={category}
				isActive={category === selectedCategory}
				onCategoryClick={() => onCategoryClick(category)}
				key={category.id}
			/>
		))
	}

	return <div>{renderCategories()}</div>
}

export default UserGroupCategoriesList
