export const MATES_ADMIN_TOKEN = 'MatesAdminToken'

export const CHALLENGE_WINNER_COUNT = 5

/* Query Keys */
export const CHALLENGE_KEY = 'CHALLENGE_KEY'
export const TOP_PICK_KEY = 'TOP_PICK_KEY'
export const RECOMMENDED_VIDEOS_KEY = 'RECOMMENDED_VIDEOS_KEY'
export const INDIVIDUAL_RECOMMENDED_RESOURCE = 'INDIVIDUAL_RECOMMENDED_RESOURCE'

export const DEFAULT_PAGE_SIZE = 10
