import axios from 'axios'
import { MATES_ADMIN_TOKEN } from '../../assets/data/constants'
import { Resource } from '../../types/stateTypes'
import { MatesPaginatedResponse } from '../../utils/query/query'

const BASE_URL = process.env.REACT_APP_API_URL
const BASE_HEADER = {
	Authorization: `Bearer ${localStorage.getItem(MATES_ADMIN_TOKEN)}`,
}

namespace ResourceService {
	export const getResources = (params: {
		page: number
		perPage: number
		type?: 'PODCAST' | 'VIDEO' | 'PDF' | 'LINK'
		categoryId?: string
		isActive?: boolean
		isHotTopic?: boolean
	}) => {
		return axios<MatesPaginatedResponse<Resource>>(BASE_URL + 'admin/resources', {
			method: 'GET',
			params,
			headers: BASE_HEADER,
		})
	}

	export const getResource = (resourceId: string) => {
		return axios<Resource>(BASE_URL + 'resources/' + resourceId, {
			method: 'GET',
			headers: BASE_HEADER,
		})
	}

	export const patchResource = (values: { id: string; data: Partial<Resource> }) => {
		return axios<Resource>(BASE_URL + 'admin/resources/' + values.id, {
			method: 'PATCH',
			data: values.data,
			headers: BASE_HEADER,
		})
	}
}

export default ResourceService
