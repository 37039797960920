import { useState } from 'react'
import { useFormik } from 'formik'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { CREATE_RESOURCE_PAGES } from '../../../assets/data/enums'
import { InitialCreateResourceState } from '../../../assets/data/InitialState'
import { addResource, resourceTypeSelector } from '../../../features/resources/Resource/resourcesSlice'
import { userGroupSelector } from '../../../features/resources/userGroupsSlice'
import { useCreateResourceMutation } from '../../../services/ResourceService'
import { formatCreateResource } from '../../../utils/formatter'
import { CreateResourceSchema } from '../../../utils/validationSchema'
import { SelectCategory, SelectResource, SelectUser } from '../molecules'
import { categorySelector } from '../../../features/resources/categoriesSlice'
import asyncTimeout from '../../../utils/asyncTimeout'

const CreateResourceModal = () => {
	const [isSuccessful, setIsSuccessful] = useState(false)
	const [isError, setIsError] = useState(false)
	const userGroupState = useMatesSelector(userGroupSelector)
	const categoryState = useMatesSelector(categorySelector)
	const resourceTypeState = useMatesSelector(resourceTypeSelector)

	const dispatch = useMatesDispatch()

	const [createResource] = useCreateResourceMutation()

	const formik = useFormik({
		initialValues: {
			...InitialCreateResourceState,
			userType: userGroupState.title,
			userTypeId: userGroupState.id,
		},
		validationSchema: CreateResourceSchema,
		onSubmit: async (values) => {
			formik.setSubmitting(true)
			const formattedResource = formatCreateResource(values, values.openedTab)

			try {
				const [data] = await Promise.all([createResource(formattedResource).unwrap(), asyncTimeout(2000)])

				if (!data) throw Error
				if (userGroupState.title === values.userType && categoryState.title === values.category && resourceTypeState === values.openedTab) {
					dispatch(addResource(data))
				}
				setIsSuccessful(true)
			} catch (error) {
				setIsError(true)
			} finally {
				formik.setSubmitting(false)
				setTimeout(() => {
					setIsSuccessful(false)
					setIsError(false)
				}, 3000)
			}
		},
	})

	const renderCreateResourcePages = () => {
		switch (formik.values.currentPage) {
			case CREATE_RESOURCE_PAGES.SELECT_USER:
				return <SelectUser formik={formik} />
			case CREATE_RESOURCE_PAGES.SELECT_CATEGORY:
				return <SelectCategory formik={formik} />
			case CREATE_RESOURCE_PAGES.SELECT_RESOURCE:
				return <SelectResource formik={formik} />
			default:
				return null
		}
	}

	return (
		<div className='w-[500px] px-6 py-3'>
			{renderCreateResourcePages()}
			{isError && <div className='text-MatesRed text-lg mt-3'>Failed to create a resource. Please check your details and try again.</div>}
			{isSuccessful && <div className='text-MatesGreen text-lg mt-3'>Resource created successfully.</div>}
		</div>
	)
}

export default CreateResourceModal
