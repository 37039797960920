import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialResourceState } from '../../../assets/data/InitialState'
import { UpdateResourceArgs } from '../../../types/serviceTypes'
import { Resource } from '../../../types/stateTypes'

export type ResourceSliceType = {
	searchValue: string
	resource: UpdateResourceArgs
	isSurveyEnabled: boolean
}

export const resourceSlice = createSlice({
	name: 'resource',
	initialState: InitialResourceState,
	reducers: {
		clearResource: (state) => {
			state.resource = {
				id: '',
				title: '',
				slug: '',
				isActive: false,
				isPinned: false,
				isSurveyEnabled: true,
				recommendedResourceId: undefined,
				link: {
					url: '',
					description: '',
				},
				pdf: {
					description: '',
					file: '',
				},
				podcast: {
					description: '',
					file: '',
				},
				video: {
					description: '',
					isHotTopic: false,
					url: '',
					thumbnail: '',
				},
			}
		},
		storeResource: (state, action: PayloadAction<Resource>) => {
			const { id, title, slug, isActive, isPinned, isSurveyEnabled, link, pdf, podcast, video, recommendedResourceId } = action.payload
			state.resource.id = id
			state.resource.title = title
			state.resource.slug = slug
			state.resource.isActive = isActive
			state.resource.isPinned = isPinned
			state.resource.isSurveyEnabled = isSurveyEnabled
			state.resource.link = link ?? null
			state.resource.pdf = pdf ?? null
			state.resource.podcast = podcast ?? null
			state.resource.video = video ?? null
			state.resource.recommendedResourceId = recommendedResourceId ?? undefined
		},
		updateResourcePin: (state, action: PayloadAction<boolean>) => {
			state.resource.isPinned = action.payload
		},
		updateResourceRecommendedResourceId: (state, action: PayloadAction<string>) => {
			state.resource.recommendedResourceId = action.payload
		},
		updateResourceIsActive: (state, action: PayloadAction<boolean>) => {
			state.resource.isActive = action.payload
		},
		updateTitle: (state, action: PayloadAction<string>) => {
			state.resource.title = action.payload
		},
		updateSlug: (state, action: PayloadAction<string>) => {
			state.resource.slug = action.payload
		},
		// --- Podcast---
		updatePodcastDescription: (state, action: PayloadAction<string>) => {
			if (state.resource.podcast === null) return
			state.resource.podcast.description = action.payload
		},
		updatePodcastFile: (state, action: PayloadAction<string>) => {
			if (state.resource.podcast === null) return
			state.resource.podcast.file = action.payload
		},
		// --- Video ---
		updateVideoDescription: (state, action: PayloadAction<string | undefined>) => {
			if (state.resource.video === null || action.payload === undefined) return
			state.resource.video.description = action.payload
		},
		updateVideoPin: (state, action: PayloadAction<boolean | undefined>) => {
			if (state.resource.video === null || action.payload === undefined) return
			state.resource.video.isHotTopic = action.payload
		},
		updateVideoThumbnail: (state, action: PayloadAction<string>) => {
			if (state.resource.video === null) return
			state.resource.video.thumbnail = action.payload
		},
		updateVideoLink: (state, action: PayloadAction<string>) => {
			if (state.resource.video === null) return
			state.resource.video.url = action.payload
		},
		// --- PDFS ---
		updatePDFContentLink: (state, action: PayloadAction<string>) => {
			if (state.resource.pdf === null) return
			state.resource.pdf.description = action.payload
		},
		updatePDFURL: (state, action: PayloadAction<string>) => {
			if (state.resource.pdf === null) return
			state.resource.pdf.file = action.payload
		},
		// --- LINK ---
		updateLinkContentLink: (state, action: PayloadAction<string>) => {
			if (state.resource.link === null) return
			state.resource.link.description = action.payload
		},
		updateLinkURL: (state, action: PayloadAction<string>) => {
			if (state.resource.link === null) return
			state.resource.link.url = action.payload
		},
		updateIsActiveSurvey: (state, action: PayloadAction<boolean>) => {
			state.resource.isSurveyEnabled = action.payload
		},
	},
})

export const resourceIdSelector = (state: RootState) => state.resource.resource.id
export const resourceSelector = (state: RootState) => state.resource.resource
export const {
	clearResource,
	storeResource,
	updateResourcePin,
	updateResourceIsActive,
	updateTitle,
	updateSlug,
	updateVideoDescription,
	updatePodcastDescription,
	updatePodcastFile,
	updateVideoPin,
	updateVideoThumbnail,
	updateVideoLink,
	updatePDFContentLink,
	updatePDFURL,
	updateLinkContentLink,
	updateLinkURL,
	updateIsActiveSurvey,
} = resourceSlice.actions
export default resourceSlice.reducer
