import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useGetPresignedDownloadUrlQuery } from '../../../services/FileService'
import { MatesLoading } from '../atoms'

type MatesAsyncImageProps = {
	fileName: string | undefined
}

const MatesAsyncImage: RFC<MatesAsyncImageProps> = ({ fileName }) => {
	const [isImageError, setIsImageError] = useState(false)
	const { data, isLoading, isError } = useGetPresignedDownloadUrlQuery({ fileName }, { skip: !fileName })

	useEffect(() => {
		setIsImageError(false)
	}, [fileName])

	if (isLoading)
		return (
			<div className='h-full w-full flex justify-center items-center rounded-lg'>
				<MatesLoading shape='circle' />
			</div>
		)

	if (!data) return <div className='w-full h-full flex justify-center items-center'>Failed to download image</div>

	if (isError || isImageError)
		return (
			<img
				className='h-full aspect-video flex justify-center rounded-lg object-contain'
				src='https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'
				alt='error'
			/>
		)

	return (
		<img
			className='h-full w-full rounded-lg drop-shadow-md object-cover aspect-video'
			src={data.url}
			alt='thumbnail'
			onError={() => setIsImageError(true)}
			loading='lazy'
		/>
	)
}

export default MatesAsyncImage
