import { skipToken } from '@reduxjs/toolkit/dist/query'
import { AnimatePresence, domAnimation, LazyMotion } from 'framer-motion'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { DotsIcon, EditIcon, RightArrowIcon } from '../../../assets/icons/icons'
import { categoriesSelector, categoryIdSelector, selectCategory } from '../../../features/resources/categoriesSlice'
import { userGroupIdSelector } from '../../../features/resources/userGroupsSlice'
import { useGetCategoriesQuery } from '../../../services/UserGroupService'
import { CardError, TitleCard } from '../../atoms/atoms'
import { CardListSkeleton } from '../../molecules/molecules'
import { motion } from 'framer-motion'
import { cardAnimations } from '../../../assets/data/InitialState'
import { openModal } from '../../../features/cores/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { Category } from '../../../types/stateTypes'
import { clearResource } from '../../../features/resources/Resource/resourceSlice'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { useReorderCategoriesMutation } from '../../../services/CategoryService'

const CategoryList = () => {
	const userGroupId = useMatesSelector(userGroupIdSelector)
	const categoryIdState = useMatesSelector(categoryIdSelector)
	const categoriesState = useMatesSelector(categoriesSelector)
	const dispatch = useMatesDispatch()

	const { isLoading, isError } = useGetCategoriesQuery(userGroupId ? { id: userGroupId, shouldStore: true } : skipToken, {
		refetchOnMountOrArgChange: true,
	})
	const [reorderCategories] = useReorderCategoriesMutation()

	const handleClickCard = (item: Category) => {
		dispatch(clearResource())
		dispatch(selectCategory(item))
	}

	const onDragEnd = async (result: DropResult) => {
		try {
			const data = await reorderCategories({
				result,
				categories: categoriesState,
				userGroupId,
			}).unwrap()
			if (!data) throw Error
		} catch (error) {
			console.log({ error })
		}
	}

	const renderCardList = () => {
		if (isLoading) return <CardListSkeleton type='normal' times={6} />
		if (isError) return <CardError />
		if (!categoriesState.length)
			return (
				<div className='w-full flex'>
					<div className='w-[9em]'></div>
					<div className='empty-list'>No Category</div>
				</div>
			)

		return (
			<AnimatePresence>
				<LazyMotion features={domAnimation} key='category-card'>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId={'categoryList'}>
							{(dropProvided) => (
								<div {...dropProvided.droppableProps} ref={dropProvided.innerRef} className='w-full'>
									{categoriesState.map((item, index) => (
										<Draggable key={item.sequence} draggableId={item.sequence.toString()} index={index}>
											{(dragProvided) => (
												<div {...dragProvided.draggableProps} {...dragProvided.dragHandleProps} ref={dragProvided.innerRef}>
													<motion.div key={index} className='flex w-full' {...cardAnimations}>
														<div className='flex items-center'>
															<div
																className='card-edit-icon mx-2 p-2'
																onClick={() => {
																	dispatch(openModal({ modalType: MODAL_TYPES.EDIT_CATEGORY }))
																	handleClickCard(item)
																}}
															>
																<EditIcon />
															</div>
															<DotsIcon className='card-edit-icon p-2 mr-2' onClick={() => {}} />
														</div>
														<div className='relative w-full'>
															{item.isAvailableToEveryone && (
																<div
																	className='absolute left-0 top-2 bottom-2 w-[6px] 
                                                        rounded-l-[3px] bg-MatesGreen'
																></div>
															)}
															<TitleCard isSelected={categoryIdState === item.id} isAnimationOn={false} onClick={() => handleClickCard(item)}>
																<div className='flex items-center justify-between w-full tracking-wider'>
																	<p className='text-[20px] font-compressed font-bold pt-[2px]'>{item.title}</p>
																	<RightArrowIcon className='ml-2' />
																</div>
															</TitleCard>
														</div>
													</motion.div>
												</div>
											)}
										</Draggable>
									))}
									{dropProvided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</LazyMotion>
			</AnimatePresence>
		)
	}

	return (
		<div className='pt-3'>
			<p className='text-MatesRed font-semibold text-2xl pl-[4.5em] mb-2'>CATEGORIES</p>
			<div className='w-full h-screen overflow-y-auto overflow-x-hidden pb-[16em]'>
				<div className='flex items-center'>
					<div className='w-[9em]'></div>
					<div
						className='w-full border-MatesWhite border-[1px] border-solid rounded-[3px]
                        py-2 px-4 text-MatesWhite text-lg cursor-pointer hover:bg-MatesBlack tracking-wide
                        hover:text-MatesWhite transition-all duration-200 text-[20px] font-compressed font-bold'
						onClick={() =>
							dispatch(
								openModal({
									modalType: MODAL_TYPES.CREATE_CATEGORY,
								})
							)
						}
					>
						CREATE NEW +
					</div>
				</div>
				{renderCardList()}
			</div>
		</div>
	)
}

export default CategoryList
